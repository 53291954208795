$brand-colors: ("red", #f44336), ("pink", #e91e63), ("purple", #9c27b0), ("indigo", #3f51b5), ("blue", #2196f3),
  ("light-blue", #03a9f4), ("cyan", #00bcd4), ("green", #4caf50), ("orange", #ff9800);

html,
body,
#root,
#root > div {
  height: 100%;
  overflow-y: hidden;
}

body {
  background-color: #fdfdfd;
}

#root > div {
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.nav-tabs.nav-pills.nav-tabs {
  border-bottom: 0;
}

.tab-content {
  flex: 1;
  overflow-y: auto;
}

.card {
  margin-top: 8px;
  margin-bottom: 8px;
}

.card .table {
  margin-bottom: 0;

  td {
    vertical-align: middle;
  }

  td.td--button {
    padding-left: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: right;
  }
}

.item-name {
  line-height: 20px;
}
.item-details {
  line-height: 15px;
}

@each $color in $brand-colors {
  a.nav-#{nth($color,1)} {
    color: nth($color, 2);
  }

  .nav-pills .nav-link.nav-#{nth($color,1)}.active {
    color: #fff;
    background-color: nth($color, 2);
    border-color: transparent;
  }
}

.nav-item.nav--with-label {
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 0;
  overflow: hidden;
  width: 25%;
  font-size: 0.9em;
  // border-color: #e9ecef;

  .tab__name {
    flex: 1;
    padding: 8px 6px;
  }

  .tab__label {
    padding: 8px 6px;
    min-width: 28px;
    text-align: center;
  }

  &.active {
    .tab__label {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.card-header {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-outline-success:hover {
  color: #28a745 !important;
  background-color: #fff !important;
  border-color: #28a745;
}

.btn-outline-danger:hover {
  color: #dc3545 !important;
  background-color: #fff !important;
  border-color: #dc3545;
}
